

export default {
  name: 'Copyright',
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper'),
    LogoElens: () => import('@/static/logo/elens.svg'),
    IconFacebook: () => import('@/assets/icons/facebook.svg'),
    IconInstagram: () => import('@/assets/icons/instagram.svg'),
    IconTwitter: () => import('@/assets/icons/twitter.svg'),
    IconYoutube: () => import('@/assets/icons/icon_youtube.svg')
  },
  computed: {
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    }
  },
  props: {
    legal: {
      type: String,
      default: ''
    },
    bgColors: {
      type: String,
      default: '#FFFFFF'
    },
    txtColors: {
      type: String,
      default: '#000000'
    }
  }
}
